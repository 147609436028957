import {  defineMessages } from 'react-intl'

const messages = defineMessages({
    title_super_admin: {
        id: 'invitation.info_page.title_super_admin',
        defaultMessage: 'You are invited as a Super Admin.'
    },
    invitation_title: {
        id: 'invitation.info_page.title',
        defaultMessage: 'You are invited to join {organization} as {role}.'
    },
    error_superadmin: {
        id: 'invitation.info_page.error_superadmin',
        defaultMessage: 'You cannot accept invitations as superadmin!'
    },
    button_accept: {
        id: 'invitation.info_page.button_accept',
        defaultMessage: 'Accept'
    },
    button_skip: {
        id: 'invitation.info_page.button_skip',
        defaultMessage: 'Skip'
    },
    you_are_logged_in_as: {
        id: 'invitation.info_page.you_are_logged_in_as',
        defaultMessage: 'You are signed in as'
    },
    last_sign_in: {
        id: 'last_sign_in',
        defaultMessage: 'Last Sign In'
    },
    today: {
        id: 'Today',
        defaultMessage: 'Today'
    },
    tomorrow: {
        id: 'Tomorrow',
        defaultMessage: 'Tomorrow'
    },
    monday: {
        id: 'Monday',
        defaultMessage: 'Monday'
    },
    tuesday: {
        id: 'Tuesday',
        defaultMessage: 'Tuesday'
    },
    wednesday: {
        id: 'Wednesday',
        defaultMessage: 'Wednesday'
    },
    thursday: {
        id: 'Thursday',
        defaultMessage: 'Thursday'
    },
    friday: {
        id: 'Friday',
        defaultMessage: 'Friday'
    },
    saturday: {
        id: 'Saturday',
        defaultMessage: 'Saturday'
    },
    sunday: {
        id: 'Sunday',
        defaultMessage: 'Sunday'
    },
    edit_date_and_time: {
        id: 'client.client_form.edit_date_and_time',
        defaultMessage: 'Edit Date and Time'
    },
    edit_workout: {
        id: 'client.client_form.edit_workout',
        defaultMessage: 'Edit Workout'
    },
    mark_as_done: {
        id: 'client.client_form.mark_as_done',
        defaultMessage: 'Mark as Done'
    },
    mark_as_missed: {
        id: 'calendar.mark_as_missed',
        defaultMessage: 'Mark as missed'
    },
    mark_as_missed_title: {
        id: 'calendar.mark_as_missed_title',
        defaultMessage: "Are you sure you want to mark as missed this workout?"
    },
    mark_as_first_in_cycle:{
        id:'calendar.mark_as_first_in_cycle',
        defaultMessage:"Mark as First In Cycle"
    },
    unmark_as_first_in_cycle:{
        id:'calendar.unmark_as_first_in_cycle',
        defaultMessage:"Unmark as First In Cycle"
    },
    mark_as_first_in_cycle_title: {
        id: 'calendar.mark_as_first_in_cycle_title',
        defaultMessage: "Are you sure you want to mark this workout as First in Cycle?"
    },
    unmark_as_first_in_cycle_title: {
        id: 'calendar.unmark_as_first_in_cycle_title',
        defaultMessage: "Are you sure you want to unmark this workout as First in Cycle?"
    },
    preview: {
        id: 'client.client_form.preview',
        defaultMessage: 'Preview'
    },
    delete: {
        id: 'calendar.delete',
        defaultMessage: 'Delete'
    },
    mark_as_done_workout_question: {
        id: 'workout.mark_as_done_workout_question',
        defaultMessage: 'Are you sure you want to mark as done this workout?'
    },
    confirm_delete: {
        id: 'messages.confirm_delete',
        defaultMessage: 'Are you sure you want to delete this workout?'
    },
    from: {
        id: 'statistics.from',
        defaultMessage: 'FROM'
    },
    to: {
        id: 'statistics.to',
        defaultMessage: 'TO'
    },
    search: {
        id: 'statistics.search',
        defaultMessage: 'SEARCH'
    },
    upload_custom_thumbnail: {
        id: 'Upload custom thumbnail',
        defaultMessage: 'Upload Custom Cover Photo'
    },
    choose_new_thumbnail: {
        id: 'Choose a new thumbnail',
        defaultMessage: 'Choose a New Cover Photo'
    },
    thumbnail_note: {
        id: 'content.thumbnail_note',
        defaultMessage: 'It may take a few minutes for the cover photo to update across all applications.'
    },
    general_details: {
        id: 'nutrition_plan.general_details',
        defaultMessage: 'General Details'
    },
    click_to: {
        id: 'workout.contentfilter.click_to',
        defaultMessage: 'Click to '
    },
    collaps: {
        id: 'workout.contentfilter.collaps',
        defaultMessage: 'collaps'
    },
    expand: {
        id: 'workout.contentfilter.expand',
        defaultMessage: 'expand'
    },
    delete_week: {
        id: 'program.delete_week',
        defaultMessage: 'Delete Week'
    },
    duplicate_week: {
        id: 'program.duplicate_week',
        defaultMessage: 'Duplicate Week'
    },
    add_week: {
        id: 'program.add_week',
        defaultMessage: 'Add Week'
    },
    public_feed_title: {
        id: 'messages.switches.public_feed_title',
        defaultMessage: '{netName} Content'
    },
    post_to_public_feed: {
        id: 'post_to_public_feed',
        defaultMessage: 'Post to {netName}'
    },
    public_feed: {
        id: 'messages.switches.public_feed',
        defaultMessage: '{netName} Content'
    },
    copy_template: {
        id: 'content.copy_template',
        defaultMessage: 'Copy Template'
    },
    save_new: {
        id: 'button.save_new',
        defaultMessage: 'Save New'
    },
    author: {
        id: 'author',
        defaultMessage: 'Author'
    },
    please_enter_the_title_of_the_workout_in_english: {
        id: 'messages.please_enter_the_title_of_the_workout_in_english',
        defaultMessage: 'Fill in the name in the required field - \"EN\"'
    },
    insert_youtube_link_here: {
        id: 'messages.insert_youtube_link_here',
        defaultMessage: 'Insert YouTube Link Here…'
    },
    only_youtube_video_links_are_allowed: {
        id: 'messages.only_youtube_video_links_are_allowed',
        defaultMessage: 'Only YouTube video links are allowed.'
    },
    youtube_video_preview: {
        id: 'messages.youtube_video_preview',
        defaultMessage: 'YouTube Video Preview'
    },
    upload_video: {
        id: 'Upload video',
        defaultMessage: 'Upload Video from Gallery'
    },
    choose_new_video: {
        id: 'Choose a new video',
        defaultMessage: 'Choose a New Video'
    },
    you_can_choose_between_youtube_link_or_uploading_your_own_video: {
        id: 'messages.you_can_choose_between_youtube_link_or_uploading_your_own_video',
        defaultMessage: "You can choose between YouTube link or uploading your own video. You can't do both."
    },
    delete_video: {
        id: 'button.delete_video',
        defaultMessage: 'Delete Video'
    },
    video_format_recommendation_label: {
        id: 'content.video_format_recommendation_label',
        defaultMessage: 'Video should be in 16:9 format for the best experience!'
    },
    glycemic_load_range: {
        id: 'nutrition_plan.glycemic_load_range',
        defaultMessage: 'Glycemic load range'
    },
    glycemic_index_range: {
        id: 'nutrition_plan.glycemic_index_range',
        defaultMessage: 'Glycemic index range'
    },
    micronutrients: {
        id: 'nutrition_plan.micronutrients',
        defaultMessage: 'Micronutrients'
    },
    category: {
        id: 'nutrition_plan.category',
        defaultMessage: 'Category'
    },
    org_content_message: {
        id: 'messages.switches.org_content',
        defaultMessage: 'By enabling this filter, you give the content you have created to your Organization. If you are a Coach - you can withdraw content from the Organizational, but only your own. If you are an Admin, you can withdraw content back to the ownership of any author, including yourself.'
    },
    org_content: {
        id: 'org_content',
        defaultMessage: 'ORG Content'
    },
    org_public: {
        id: 'org_public',
        defaultMessage: 'Post As ORG to {netName}'
    },
    org_public_feed_title: {
        id: 'messages.switches.org_public_feed_title',
        defaultMessage: 'Post As ORG to {netName}'
    },
    org_public_feed: {
        id: 'messages.switches.org_public_feed',
        defaultMessage: 'By including this filter, you place the created content on the {netName}, so that the Organization is considered as an author. This means that the Organization is going to be listed as an author of the Content, even in your own workouts list. You are still able to withdraw content from the public to your private list at any time. If you are a Trainer, you can only withdraw content that you created. If you are an Organization Admin, you are able to withdraw any content that is considered as Organizational property, no matter who the creator is.'
    },
    org_content_title: {
        id: 'messages.switches.org_content_title',
        defaultMessage: 'Organization Content'
    },
    good_for: {
        id: 'nutrition_plan.good_for',
        defaultMessage: 'Good for'
    },
    counter_productive_for: {
        id: 'nutrition_plan.counter_productive_for',
        defaultMessage: 'Counterproductive for'
    },
    diet: {
        id: 'nutrition_plan.diet',
        defaultMessage: 'Diet'
    },
    archived: {
        id: 'button.archived',
        defaultMessage: 'Archived'
    },
    details: {
        id: 'details',
        defaultMessage: 'Details'
    },
    incline: {
        id: 'workout.incline',
        defaultMessage: 'Incline'
     },
     speed: {
        id: 'workout.speed',
        defaultMessage: 'Speed'
     },
     weight: {
        id: 'workout.weight',
        defaultMessage: 'Weight'
     },
     rpm: {
        id: 'workout.rpm',
        defaultMessage: 'RPM'
     },
     pace: {
        id: 'workout.pace',
        defaultMessage: 'Pace'
     },
     power: {
        id: 'workout.power',
        defaultMessage: 'Power'
     },
     level: {
        id: 'workout.level',
        defaultMessage: 'Level (1-20)'
     },
    color: {
        id: 'workout.color',
        defaultMessage: 'Color'
    },
    distance: {
        id: 'workout.distance',
        defaultMessage: 'Distance'
    },
    floors: {
        id: 'workout.floors',
        defaultMessage: 'Floors'
    },
    round: {
        id: 'workout.round',
        defaultMessage: 'Round'
    },
    upload_avatar_image: {
        id: 'Upload avatar image',
        defaultMessage: 'Upload avatar image'
    },
    choose_new_avatar: {
            id: 'Choose a new avatar',
            defaultMessage: 'Choose a new avatar'
    },
    note: {
        id: 'note',
        defaultMessage: 'Comment'
     },
     reps: {
        id: 'workout.reps',
        defaultMessage: 'Reps'
     },
     view_note: {
        id: 'button.view_note',
        defaultMessage: 'VIEW NOTE'
     },
     add_note: {
        id: 'button.add_note',
        defaultMessage: 'ADD COMMENT'
     },
     workout_title: {
        id: 'workout.content_workout.workout_title',
        defaultMessage: 'Workout'
    },
    workout_group: {
        id: 'workout.group',
        defaultMessage: 'Group'
    },
    warmup: {
        id: 'workout.content_workout.warmup',
        defaultMessage: 'Warmup'
    },
    cooldown: {
        id: 'workout.content_workout.cooldown',
        defaultMessage: 'Cooldown'
    },
    training_level: {
        id: 'Training Level',
        defaultMessage: 'Training Level'
    },
    goals: {
        id: 'Goals',
        defaultMessage: 'Goals'
    },
    privacy_and_terms: {
        id: 'login_page.privacy_&_terms',
        defaultMessage: 'Privacy Policy & Terms Of Use.'
    },
    premium_reports: {
        id: 'premium_reports',
        defaultMessage: 'Premium Reports'
    },
    premium_code: {
        id: 'premium_code',
        defaultMessage: 'Premium Code'
    },
    premium_subscription_type: {
        id: 'premium_subscription_type',
        defaultMessage: 'Subscription Type'
    }

})
export default messages;