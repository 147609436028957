import {Invitation, User} from "myfitworld-model";
import React, {Fragment, useContext, useState} from "react";
import {Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Box, Dialog, DialogTitle, DialogActions, makeStyles, createStyles, Typography} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import copy from "copy-to-clipboard";
import InviteUserForm from "./InviteUserForm";
import useTableStyles from "../../theme/useTableStyles";
import Role from "myfitworld-model/dist/enums/Role";
import {useOrganizationProvider} from "../../providers/OrganizationProvider";
import SideDrawer from "../SideDrawer";
import LoadingSpinner from "../LoadingSpinner";
import globalState from "../../globalState";
import {useStoreState} from "pullstate";
import useNavigation from "../../hooks/useNavigation";
import {revokeInvitationFunctions} from "../../api/invitationsApi";
import { useMfwThemeProvider } from "myfitworld-utils";
import globalMessages from '../../messages';
import invitationMessages from './messages';
import { useIntl } from 'react-intl';
import useOrganizationInvitations from "../../hooks/Organizations/useOrganizationInvitations";
import { useUserProvider } from "../../providers/UserProvider";
import useWLClientsRequests from "../../hooks/Users/useWLClientsRequests";
import UserContext from "src/contexts/UserContext";
import useAdminTrainerCountsAndRoles from "src/hooks/useAdminTrainerCountsAndRoles";

interface Props {
  roles: Role[];
  showRole: boolean;
  createActionLabel: string;
  formHelperText?: string;
  invitationsBlocked?: boolean;
  setLoadingRevokeDisableCreateInvitationButton?: (a:boolean)=>void,
  loadingRevokeDisableCreateInvitationButton?:boolean,
  disableFormIfQuantityAndCard?:()=>void,
  disableInvitationForm?: boolean;
}
interface ConfirmationDialogState {
  isOpen: boolean;
  invitation: Invitation | undefined;
}

const useStyles = makeStyles(() =>
  createStyles({
    dialogActions: {
      display: 'flex', 
      justifyContent: 'space-between'
    },
  }),
);

const InvitationsTable = (
  {roles, showRole,
    createActionLabel, formHelperText, invitationsBlocked,
    loadingRevokeDisableCreateInvitationButton,
    setLoadingRevokeDisableCreateInvitationButton,
    disableFormIfQuantityAndCard,
    disableInvitationForm
  }: Props) => {
  const tableClasses = useTableStyles();
  const {formatMessage} = useIntl();
  const classes = useStyles();
  const [formOpen, setFormOpen] = useState<boolean>(false);
  const theme = useMfwThemeProvider();
  const [{selectedOrganization}] = useOrganizationProvider();
  const role = useStoreState(globalState, s => s.currentRole);
  const [loadingButtonsId, setLoadingButtonsId] = useState("");
  const [isOpenConfirmationDialog, setIsOpenConfirmationDialog] = useState<ConfirmationDialogState>({ isOpen: false, invitation: undefined });
  const { navigation } = useNavigation();
  const {user: authUser} = useUserProvider();
  const {organizationInvitations: invitations, setInvitations, isLoadingInvitationsList, fetchAgain} = useOrganizationInvitations({
    userId: authUser?.id,
    organizationId: authUser?.currentOrganization,
    roles: roles,
    role: role,
    isWhiteLabel: theme.theme.isWhiteLabel
  });
  const {acceptRequest, rejectRequest} = useWLClientsRequests();
  const userContext = useContext(UserContext);
  const currentOrganization = useStoreState(globalState, s => s.currentOrganization);
  const currentOrganizationId = currentOrganization?.id || '';
  const {isArchivedUser: isArchivedLoginUser} = useAdminTrainerCountsAndRoles(currentOrganizationId, userContext?.user);

  const revokeInvitation = (invitationId: string) => {
    const isClient = roles.includes(Role.Client);
    setLoadingRevokeDisableCreateInvitationButton&&setLoadingRevokeDisableCreateInvitationButton(true)
    revokeInvitationFunctions(invitationId, isClient, selectedOrganization?.id||"").then((res:boolean)=>{
      setLoadingRevokeDisableCreateInvitationButton&&setLoadingRevokeDisableCreateInvitationButton(false)
      if(res){
        disableFormIfQuantityAndCard&&disableFormIfQuantityAndCard();
        fetchAgain();
      }
    })
  }

  const confirmationDialogAction=(e: React.MouseEvent<HTMLButtonElement, MouseEvent>)=>{
    if(isOpenConfirmationDialog.invitation?.id){
      e.stopPropagation();
      revokeInvitation(isOpenConfirmationDialog.invitation?.id);
      setIsOpenConfirmationDialog({isOpen:false, invitation:undefined});
    }
  }

  const getConnectionRequestBody = (invitation: Invitation) => {
      if(invitation.isInSendingQueue){
        return formatMessage(invitationMessages.pending);
      } else if ((invitation as Invitation).invitationSent){
        return (
          <Alert severity="success">
            {formatMessage(invitationMessages.invitation_sent)}
          </Alert>
        );
      } else {
        return (invitation as Invitation).role === Role.Client ?
          formatMessage(globalMessages.no) :
          formatMessage(invitationMessages.pending);
      }
    }

  return (
    <Fragment>
       
          <Dialog
            open={isOpenConfirmationDialog.isOpen}
            onClose={()=>{setIsOpenConfirmationDialog({isOpen:false, invitation:undefined})}}
          >
            <DialogTitle>{formatMessage(invitationMessages.are_you_sure_you_want_to_remove, {name: `${isOpenConfirmationDialog.invitation?.firstName} ${isOpenConfirmationDialog.invitation?.lastName}`})}</DialogTitle>
            <DialogActions className={classes.dialogActions}>
              <Button onClick={()=>{setIsOpenConfirmationDialog({isOpen:false, invitation:undefined})}} color="secondary">{formatMessage(globalMessages.cancel)}</Button>
              <Button
                onClick={(e)=>{confirmationDialogAction(e)}}
                color="primary"
                autoFocus
              >
                {formatMessage(globalMessages.yes)}
              </Button>
            </DialogActions>
          </Dialog>

      <div className={tableClasses.tableActionBarPending}>
        <Alert severity="info" style = {{marginBottom:"10px"}}>
          {formatMessage(invitationMessages.check_spam)}
          <br></br>
          {formatMessage(invitationMessages.check_spam2)}
        </Alert>
          <Button
          color='primary'
          onClick={_ => setFormOpen(true)}
          disabled={!!isArchivedLoginUser}
        >
          {formatMessage((invitationMessages as any)[createActionLabel])}
        </Button>
      </div>

      {loadingRevokeDisableCreateInvitationButton && <Box display="flex" alignItems="center"><LoadingSpinner marginTop={0}/></Box>}
      {isLoadingInvitationsList ? <LoadingSpinner marginTop={50}/> :
      invitations.length > 0 ?
      <TableContainer>
        <Table className={tableClasses.table} size="medium">
          <TableHead>
            <TableRow>
              <TableCell align="left">{formatMessage(globalMessages.name)}</TableCell>
              <TableCell align="left">{formatMessage(globalMessages.email)}</TableCell>
              {showRole && <TableCell align="left">{formatMessage(globalMessages.role)}</TableCell>}
              <TableCell align="left">{formatMessage(globalMessages.created_at)}</TableCell>
              <TableCell align="left">{formatMessage(invitationMessages.invitation_sent_question)}</TableCell>
              <TableCell align="center">{formatMessage(invitationMessages.connection_link)}</TableCell>
              <TableCell align="center">{formatMessage(globalMessages.actions)}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {invitations.map((invitation) => (
              <TableRow key={invitation.id}>
                <TableCell align="left">{
                  `${invitation.lastName ? invitation.lastName + ' ' : ''}${invitation.firstName ? invitation.firstName : ''}`
                }</TableCell>
                <TableCell align="left">{invitation.email || ''}</TableCell>
                {/* <TableCell align="left">{invitation.phoneNumber || ''}</TableCell> */}

                {showRole && <TableCell align="left">{formatMessage((invitationMessages as any)[(invitation as Invitation).role.toLowerCase()])}</TableCell>}

                <TableCell align="left">
                  {invitation.createdAt && formatDistanceToNow(new Date(invitation.createdAt), {addSuffix: true})}
                </TableCell>

                <TableCell align="left">
                  {loadingButtonsId === invitation.id ? <LoadingSpinner marginTop={0}/> : getConnectionRequestBody(invitation)}
                </TableCell>

                <TableCell align="center">
                  <Button onClick={(e) => {
                    e.stopPropagation();
                    copy(`https://${theme.theme.domain}/i/${invitation.id}`);
                    e.currentTarget.className += " Mui-disabled";
                  }}
                  disabled={loadingRevokeDisableCreateInvitationButton}>
                    {formatMessage(invitationMessages.copy_link)}
                  </Button>
                </TableCell>
                <TableCell align="center">
                    <Button
                      onClick={(e) => {
                        setIsOpenConfirmationDialog({isOpen:true, invitation:invitation as Invitation})
                      }}
                      disabled={loadingRevokeDisableCreateInvitationButton || invitationsBlocked}
                      color="secondary"
                    >
                      {formatMessage(globalMessages.remove)}
                    </Button>
                </TableCell>
              </TableRow>

            ))}
          </TableBody>
        </Table>
      </TableContainer>
      :
      <Box display='flex' justifyContent='center' alignItems='center'>
        <Typography variant='h5' color='primary'>{createActionLabel === 'create_member' ? formatMessage(invitationMessages.dont_have_members) : formatMessage(invitationMessages.dont_have_invitations)}</Typography>
      </Box>
      }
      {
      <SideDrawer isOpen={formOpen} onClose={() => {setFormOpen(false);}}>
        {disableInvitationForm ?
          <Alert severity='info' style={{margin: '24px auto 0 auto', maxWidth: 520, padding: 24}}>
            {formatMessage(invitationMessages.free_clients_limit)}
            <Button onClick={() => navigation('/billing')}>{formatMessage(globalMessages.billing_details)}</Button>
          </Alert>
          :
          <InviteUserForm
            onSubmit={() => {
              setFormOpen(false);
              fetchAgain();
            }}
            roles={roles}
            createActionLabel={createActionLabel}
            helperText={formHelperText}
            invitationsBlocked={invitationsBlocked}
          />
        }
      </SideDrawer>
      }

    </Fragment>
  );
};

export default InvitationsTable;
