import React, { useMemo } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { FileCopy, AddCircleOutlineRounded as Add } from '@material-ui/icons';

const fullWidthContainerStyle = { width: '100%', height: '100%', position:'absolute'};
const fullWidthIconStyle = { width: '100%', height: '100%',borderRadius: '4px'};
  
const SimpleMenu = ({
  options,
  onSelect,
  disabled,
  customIcon,
  withFullStyle,
  upperCaseFormat
} : {
  options: Array<{ label: string, action: string }>,
  onSelect: (action?: string) => void,
  disabled?: boolean,
  customIcon?: 'fileCopy' | 'add',
  withFullStyle?:boolean,
  upperCaseFormat?:boolean,
}) => {
  const [ anchorEl, setAnchorEl ] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (option?: string) => () => {
    onSelect(option);
    setAnchorEl(null);
  };

  const transformIconType = ()=>{
    switch(customIcon){
      case 'fileCopy':
        return <FileCopy/>
      case 'add':
        return <Add/>
      default:
        return (<MoreVertIcon />)
    }
     
  }
  // Custom css in case we want the simple menu to be full with:  
  const memoizedStyles = useMemo(() => {
    return {
      containerStyle: withFullStyle ? fullWidthContainerStyle : {},
      iconStyle: withFullStyle ? fullWidthIconStyle : {},
    };
  }, [withFullStyle]);
  return (
    <div style={{ alignSelf: 'center', ...memoizedStyles.containerStyle }}>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
        size="small"
        style={memoizedStyles.iconStyle}
      >
        {transformIconType()} 
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose()}
      >
        {options.map((option, index) =>
          <MenuItem onClick={handleClose(option.action)} key={index} disabled={disabled}>
            {upperCaseFormat ? option.label.toLocaleUpperCase() : option.label}
          </MenuItem>
        )}
      </Menu>
    </div>
  );
};

export default SimpleMenu;
