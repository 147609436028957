import React, {useContext, useEffect, useState} from 'react';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import LanguageContext from "./LanguageContext";
import {Badge, Box,Typography, createStyles, makeStyles} from "@material-ui/core";
import {Resource} from "myfitworld-model";
import { FiberManualRecord } from '@material-ui/icons';
import { Locale } from "../../types/config.types";
import languageMessages from '../../messages';
import { useIntl } from 'react-intl';
import { useUserProvider } from 'src/providers/UserProvider';
import SimpleMenu from '../SimpleMenu';

const useStyles = makeStyles(() =>
  createStyles({
    boxContainer: {
      display: 'flex',
      minWidth: "150px",
      width: "fit-content",
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    dotStyle:{
      fill: "#f44336 !important",  
    },
    margin:{
      marginRight:" 0 10px"
    }
  })
);

interface LanguageInterface{
  label:string;
  displayed:boolean,
  action:string;
}

function LanguageSelect<T extends Resource>({watch, getValues}: {watch: (_: Array<string>, ) => T, getValues:() => any}) {
// We should see the following languages:
  // en - mandatory
  // our language preferance from user
  // any language that has saved values in title or description 
  const {language, languages: allLanguages, setLanguage} = useContext(LanguageContext);
  const {user} = useUserProvider();
  
 
  function transformLanguages() {
    return allLanguages.map((lan) => {
      if ( 
        lan === 'en' ||  
        lan === user?.language ||  
        (getValues().title && getValues().title[lan] !== "") ||
        (getValues().description && getValues().description[lan] !== "")  || language===lan
      ) {
        return { label: lan, action: lan, displayed:true } as LanguageInterface;
      } else {
        return { label: lan, action: lan, displayed:false} as LanguageInterface;
      }
    }) as LanguageInterface[];
  }
  const [selectedLanguages, setSelectedLanguages] = useState<LanguageInterface[]>(transformLanguages);


  const classes = useStyles();
  const {formatMessage} = useIntl();

  useEffect(()=>{
    setLanguage('en');
    setSelectedLanguages(transformLanguages);
} ,[getValues])

  const handleChange = (event: React.MouseEvent<HTMLElement>, lang: string | Locale) => {
    if (lang) {
      setLanguage(lang);
    }
  };

  function handleAddLanguageToSelected(action?: string){
      if (action) {
        const updatedLanguages = selectedLanguages.map(lang => 
          lang.action === action
            ? { ...lang, displayed: true } // Set displayed to true for the selected language
            : lang // Keep others the same, or set `displayed` to false if needed
        );
        setSelectedLanguages(updatedLanguages); // Update the state with the modified languages
        setLanguage(action)
    }
  }

  return (
    <Box display='flex' flexDirection='column'>
      <Box className={classes.boxContainer}>
        <FiberManualRecord className={classes.dotStyle} style={{ width: '14px' }} />
        <Typography variant="h6" color="error">{formatMessage(languageMessages.mandatory_field)}</Typography>
      </Box>
      <Box style = {{display:"flex", alignItems:"center",}}>
        <ToggleButtonGroup
          size="small"
          value={language}
          exclusive
          onChange={handleChange}
          aria-label="Language select"
          className={classes.margin}
          style={{marginRight:"10px"}}
        >
          {selectedLanguages.map(lang => (
            lang.displayed && <ToggleButton value={lang.label} aria-label={lang.label} key={lang.label}>
              <Badge variant="dot" color="error" invisible={lang.label !== "en"}>
                <label>{lang.label}</label>
              </Badge>
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
        <SimpleMenu
          options={selectedLanguages.filter((lan)=>lan.displayed===false)}
          onSelect = {handleAddLanguageToSelected}
          customIcon='add'
          upperCaseFormat = {true}
        />
      </Box>
    </Box>
  );
}

export default LanguageSelect
