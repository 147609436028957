import React, { useState } from 'react';
import { Box, TextField, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { useStoreState } from "pullstate";
import globalState from "../../globalState";
import {useIntl} from "react-intl";
import messages from "./messages";

const ContactSupportForm: React.FC = () => {
  const {formatMessage} = useIntl();
  const currentOrganization = useStoreState(globalState, s => s.currentOrganization);

  const [subject, setSubject] = useState<string>('');
  const [bodyText, setBodyText] = useState<string>('');
  const [errors, setErrors] = useState<{ subject: boolean; bodyText: boolean }>({
    subject: false,
    bodyText: false,
  });

  const handleSendEmail = () => {
    const hasErrors = {
      subject: !subject.trim(),
      bodyText: !bodyText.trim(),
    };

    setErrors(hasErrors);

    if (hasErrors.subject || hasErrors.bodyText) {
      return;
    }

    window.location.href = `mailto:support@myfitworld.net?subject=${encodeURIComponent(`${currentOrganization?.name} - ${subject}`)}&body=${encodeURIComponent(bodyText)}`;
    setSubject('');
    setBodyText('');
  };

  return (
    <Box>
      <Box style={{ marginBottom: 10 }}>
        <Box mb={1}>
          <Typography variant="h6" gutterBottom>{formatMessage(messages.your_subject)}</Typography>
        </Box>
        <TextField
          name="subject"
          label={formatMessage(messages.your_subject)}
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
          type="text"
          margin="dense"
          fullWidth
          error={errors.subject}
          helperText={errors.subject ? formatMessage(messages.subject_required) : ""}
        />
      </Box>

      <Box style={{ marginBottom: 10 }}>
        <Box mb={1}>
          <Typography variant="h6" gutterBottom>{formatMessage(messages.your_message)}</Typography>
        </Box>
        <TextField
          name="bodyText"
          label={formatMessage(messages.your_message)}
          value={bodyText}
          onChange={(e) => setBodyText(e.target.value)}
          minRows={5}
          multiline
          type="text"
          margin="dense"
          fullWidth
          error={errors.bodyText}
          helperText={errors.bodyText ? formatMessage(messages.message_required) : ""}
        />
      </Box>

      <Box mt={2}>
        <Button color="primary" variant="contained" onClick={handleSendEmail} disabled={subject==="" || bodyText===""}>
          {formatMessage(messages.send_message)}
        </Button>
      </Box>
    </Box>
  );
};

export default ContactSupportForm;
